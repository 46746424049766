<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-calendar"/>
          Ein neues Projekt Anlegen
        </CCardHeader>

        <CCardBody>
          <NeuesProjekt v-model="projekt" :uuid="uuid"/>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import NeuesProjekt from '@/components/NeuesProjekt'

export default {
  name: 'KundeNew',
  components: {
    NeuesProjekt
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      projekt: null
    }
  },
  watch: {
    projekt: function () {
      if (this.projekt) {
        this.$router.push({ path: '/projekte/uebersicht/' + this.uuid })
      }
    }
  }
}
</script>
