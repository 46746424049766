<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm class="no-mb" @submit.prevent="newProjekt()">

      <ProjektText v-model="name" formLabel="Name" />
      <ProjektText v-model="zusatz" formLabel="Zusatz" rules=""/>
      <ProjektText v-model="strasse" formLabel="Strasse" rules=""/>
      <ProjektText v-model="hausnr" formLabel="Hausnummer" rules="" />
      <ProjektText v-model="plz" formLabel="Postleitzahl" rules=""/>
      <ProjektText v-model="ort" formLabel="Ort" rules="" />
      <ProjektText v-model="ansprechpartner" formLabel="Ansprechpartner" rules=""/>
      <ProjektText v-model="telefon" formLabel="Telefon" rules=""/>
      <ProjektTextArea v-model="bemerkung" formLabel="Bemerkung" rules=""/>

      <FormError v-if="formError" :errormessage="formError"/>

      <CRow class="mt-4">
        <CCol col="12" class="text-right">
          <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Projekt anlegen</CButton>
        </CCol>
      </CRow>

    </CForm>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import ProjektText from '@/components/FormElements/Text'
import ProjektTextArea from '@/components/FormElements/TextArea'

export default {
  name: 'KundeNew',
  components: {
    FormError,
    ProjektText,
    ProjektTextArea
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      kunde: null,
      name: '',
      zusatz: '',
      strasse: '',
      hausnr: '',
      plz: '',
      ort: '',
      ansprechpartner: '',
      telefon: '',
      bemerkung: '',
      formError: null
    }
  },
  mounted () {
    this.loadKunde()
    this.setDefaults()
  },
  methods: {
    setDefaults () {
      this.name = ''
      this.zusatz = ''
      this.strasse = ''
      this.hausnr = ''
      this.plz = ''
      this.ort = ''
      this.ansprechpartner = ''
      this.telefon = ''
      this.bemerkung = ''
      this.formError = null
    },
    loadKunde () {
      Vue.axios.get('/kunden/get/' + this.uuid)
        .then((response) => {
          this.kunde = response.data
        })
    },
    newProjekt () {
      Vue.axios.post('/projekte/post', {
        kunde: this.uuid,
        name: this.name,
        zusatz: this.zusatz,
        strasse: this.strasse,
        hausnr: this.hausnr,
        plz: this.plz,
        ort: this.ort,
        ansprechpartner: this.ansprechpartner,
        telefon: this.telefon,
        bemerkung: this.bemerkung
      }).then((response) => {
        this.$snotify.success('Ein neues Projekt wurde angelegt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
        this.$emit('input', response.data.projektid)
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
